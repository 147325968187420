
import { Options, Vue } from "vue-property-decorator";
import { namespace } from "s-vuex-class";

const AppointmentsModule = namespace("appointments");
@Options({
  components: {},
})
export default class AppointmentsHero extends Vue {
  @AppointmentsModule.Getter("getNextAppointment")
  public nextAppointment: any;

  public get time() {
    return this.nextAppointment?.time ? `${this.nextAppointment.time} Uhr` : null;
  }
}
